import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from "react-helmet";
import { WebappContext, AccountContext, WhiteLabelContext, LanguageContext, GeolocationContext } from "ToolboxUtils/web/context/context";
import ResultsView from 'ToolboxComponents/webapp/pages/results-view/results-view';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import { PAGE_LOAD_WAITING } from 'Params/globals';

const Search = props => {
  const webappContext = useContext(WebappContext);
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [results,setResults] = useContext(WebappContext).usePath('results');
  const [search,setSearch] = useContext(WebappContext).usePath('search');
  const [tagNames, setTagNames] = useState();
  const [pages] = useContext(WhiteLabelContext).usePath('pages');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [startLoader, setStartLoader] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);
  const [isReloadingProducts, setIsReloadingProducts] = useState(false);
  const [hasOneHighlightProductOnly, sethasOneHighlightProductOnly] = useState(false);
  const [geolocation, setGeolocation] = useContext(GeolocationContext).usePath();
  const [products, setProducts] = useState([]);


  useEffect(() => {
    setTimeout(() => showLoader(true), PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
    return () => {
      setPageInitTime(null);
    }
  }, []);

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
    }
  }
  const openSignInModal = status => {
    setSignIn({opened: true, from: 'fav'});
  }
 
  const desireId = search ? search.desireId : false;

  // const desire = useApiChuchoteurs.get(`/desires/${desireId}`, {
  //   params: {whiteLabelId: whiteLabel.id, filter: 'results'},
  //   depends: desireId
  // });
  const desires = useApiChuchoteurs.get('/desires', {
    params: {language: language, whiteLabelId: whiteLabel.id, cityId: whiteLabel.city.id, filter: 'search-desires'},
  });
  const moments = useApiChuchoteurs.get('/moments', {
    params: {filter: 'search', language: language},
  });
  const cities = useApiChuchoteurs.get('/cities', {
    params: {language: language, whiteLabelId: whiteLabel.id},
  });
  const profiles = useApiChuchoteurs.get('/profiles', {
    params: {language: language, whiteLabelId: whiteLabel.id},
  });

  const getProducts = async searchParams => {
    const previousSearchParams = search || {};
    const params = {
      ...previousSearchParams,
      ...searchParams,
    }
    setSearch(params);
    try {
        const products = await apiChuchoteurs.get('/products/', {
        params: {
          ...params,
          language: language,
          whiteLabelId: whiteLabel.id,
          filter: 'search-products',
          time: new Date().getTime() // since we pass a momentId, we use time to tell that the request may lead to different result
        }
      });
      setResults(products);
      setProducts([...products]);
    } catch(err) {
      console.log(err);
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }

  const getTags = async desireId => {
    try {
      const tags = await apiChuchoteurs.get('/tags/', {
        params: {
          filter: 'results', 
          desireId: desireId || [desires.data[0].id, desires.data[1].id, desires.data[2].id, desires.data[3].id], 
          language, 
          whiteLabelId: whiteLabel.id
        },
      });
      setTagNames(tags);
    } catch(err) {
      console.log(err);
      apiChuchoteurs.post(`/logs`, {
        body: {error: {...err, type: 'front'}}
      })
    }
  }

  useEffect(() => {
    if (search && desires.isComplete) {
     getTags(search.desireId);
    }
  }, [search, desires.isComplete])


  // First loading of products
  useEffect(() => {
    // only load  products if there is no previous search
    if ((!search || search.isGeolocated !== geolocation.isGeolocated) && desires.isComplete) {
      const params = {
        desireId: [desires.data[0].id, desires.data[1].id, desires.data[2].id, desires.data[3].id],
        momentId: 1,
        locationId: whiteLabel.isInZone ? 0 : whiteLabel.city.id,
        transportId: account && account.transportId ? account.transportId : 2,
        profileId: account && account.profileId ? account.profileId : 1,
        budgetId: 1,
        userLatitude: geolocation.coordinates.latitude,
        userLongitude: geolocation.coordinates.longitude,
        searchLatitude: whiteLabel.latitude,
        searchLongitude: whiteLabel.longitude,
        isInZone: whiteLabel.isInZone,
        isGeolocated: geolocation.isGeolocated,
      }
      getProducts(params);
    }
  // geolocation can happen any time after the first loading, so we must reload products if the geolocated city changed
  }, [whiteLabel.city.id, desires.isComplete, search, geolocation.isGeolocated, geolocation.coordinates.latitude, geolocation.coordinates.longitude]); 

  useEffect(() => {
    if (results) {
      setProducts([...results]);
    }
  // results can come from getProducts or from homepage (slider desires)
  },[results]);
  const isContentLoaded = search && results && tagNames && desires.isComplete && moments.isComplete  && cities.isComplete && profiles.isComplete;
  useIsContentLoaded(isContentLoaded);
  return (
    <>
      {isContentLoaded ?
        <ResultsView
          page='results'
          isSearch={true}
          products={products}
          tagNames={tagNames}
          desires={desires.data}
          defaultDesire={desires.data[0]}
          isReloadingProducts={isReloadingProducts}
          onSearchChange={async params => {
            setIsReloadingProducts(true);
            await getProducts(params);
            webappContext.setElement('resultsView.pageVisible', 1);
            webappContext.setElement('resultsView.selectedListCardId', undefined);
            webappContext.setElement('resultsView.selectedSlideId', undefined);
            setIsReloadingProducts(false);
          }}
          defaultMoment={search.momentId}
          momentsList={moments.data}
          defaultLocation={search.locationId}
          locations={cities.data}
          defaultProfile={search.profileId}
          profiles={profiles.data}
          addFavorite={async (id) => {
            if (account && account.id) {
              try {
                const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                  token: localStorage.token
                })
                updateFav(id);
              } catch(err) {
                apiChuchoteurs.post(`/logs`, {
                  body: {error: {...err, type: 'front'}}
                })
              }
            } else {
              openSignInModal(true);
            }
          }}
          deleteFavorite={async (id) => {
            if (account && account.id) {
              try {
                const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                  token: localStorage.token
                })
                updateFav(id);
              } catch(err) {
                apiChuchoteurs.post(`/logs`, {
                  body: {error: {...err, type: 'front'}}
                })
              }
            } else {
              openSignInModal(true);
            }
          }}
        />
        : isLoaderDisplayed
          ? <Loader />
          : null
      }
    </>
  );
}

export default Search;
